import React from "react";
import {
  FaFacebook,
  FaInstagram,
  FaLinkedin,
  FaGithub,
  FaSpotify,
} from "react-icons/fa";
import { Link } from "react-router-dom";
import datacamp_donates from "../images/donates.jpg";

export default function Footer() {
  return (
    <>
      <footer>
        <section className="bg-white grid md:grid-cols-3 grid-cols-1 sm:px-16 px-8 py-8">
          <div className="py-12">
            <h3 className="font-ibm font-bold text-2xl mb-5 uppercase">
              OverView
            </h3>
            <ul className="font-light capitalize">
              <li className="my-2 hover:text-dark-red">About us</li>
              <li className="my-2 hover:text-dark-red">What we do</li>
              <li className="my-2 hover:text-dark-red">
                <Link to={"/events"}>Events</Link>
              </li>
              {/* <li className="my-2">ITCast Podcast</li>
            <li className="my-2">Our Teams</li> */}
            </ul>
          </div>
          <div className="py-12">
            <h3 className="font-ibm font-bold text-2xl mb-5 uppercase">
              Our Podcasts
            </h3>
            <ul className="font-light capitalize">
              <li className="my-2">Data Science</li>
              <li className="my-2">Web Development</li>
              <li className="my-2">Graphic Design</li>
              <li className="my-2">Mobile Development</li>
            </ul>
          </div>
          <div className="py-12">
            <h3 className="font-ibm font-bold text-2xl mb-5 uppercase">
              Follow Us
            </h3>
            <ul className="flex">
              <li className="hover:text-[#3b5998] text-3xl mr-2">
                <a
                  target="_blank"
                  rel="noreferrer"
                  href="https://www.facebook.com/ITCBlida"
                >
                  <FaFacebook />
                </a>
              </li>
              <li className="hover:text-[#8a3ab9] text-3xl mr-2">
                <a
                  target="_blank"
                  rel="noreferrer"
                  href="https://www.instagram.com/IT_community"
                >
                  <FaInstagram />
                </a>
              </li>
              <li className="hover:text-[#0072b1] text-3xl mr-2">
                <a
                  target="_blank"
                  rel="noreferrer"
                  href="https://www.linkedin.com/in/it-community-23373720a/"
                >
                  <FaLinkedin />
                </a>
              </li>
              <li className="hover:text-[#171515] text-3xl mr-2">
                <a
                  target="_blank"
                  rel="noreferrer"
                  href="https://github.com/ITCommunity-Club"
                >
                  <FaGithub />
                </a>
              </li>
              <li className="hover:text-[#1DB954] text-3xl mr-2">
                <a
                  target="_blank"
                  rel="noreferrer"
                  href="https://open.spotify.com/show/1nnC8eq7tZFUNUoAbvKrH1?si=06354aa2cbbb4992"
                >
                  <FaSpotify />
                </a>
              </li>
            </ul>
            <div className="pt-8">
              <h3 className="font-ibm font-bold text-2xl uppercase">
                Our partners
              </h3>
              <div className="relative w-96">
                <a
                  href="https://www.datacamp.com/donates"
                  rel="noopener noreferrer"
                  target="_blank"
                >
                  <img
                    className="w-1/2 h-1/2 object-cover"
                    src={datacamp_donates}
                    alt="Datacamp Donate Link"
                  />
                </a>
              </div>
            </div>
          </div>
        </section>
        <p className="text-center text-white font-ibm sm:text-sm text-xs py-4 capitalize">
          COPYRIGHT © {new Date().getFullYear()} IT COMMUNITY. All rights
          reserved.
        </p>
      </footer>
    </>
  );
}

import React, { useContext, useEffect, useState } from "react";
import { SidebarContext } from "../context/SidebarContext";

export default function Cursor() {
  const { hovered } = useContext(SidebarContext);

  const [position, setPosition] = useState({ x: 0, y: 0 });

  useEffect(() => {
    const addEventListeners = () => {
      document.addEventListener("mousemove", mMove);
    };

    const removeEventListeners = () => {
      document.removeEventListener("mousemove", mMove);
    };

    const mMove = (el) => {
      setPosition({ x: el.clientX, y: el.clientY });
    };

    addEventListeners();
    return () => removeEventListeners();
  }, []);

  return (
    <div
      className={`w-[40px] h-[40px] rounded-full -translate-x-1/2 -translate-y-1/2 fixed bg-white border border-white mix-blend-difference pointer-events-none z-50 transition ease-in-out duration-300 ${
        hovered ? " scale-150" : "scale-100"
      }`}
      style={{
        left: `${position.x}px`,
        top: `${position.y}px`,
      }}
    ></div>
  );
}

import { useEffect, useState } from "react";
import binary_icon from "../images/icons/binary-code.png";
import terminal_icon from "../images/icons/terminal.png";
import open_bracket_icon from "../images/icons/open-bracket.png";
import close_bracket_icon from "../images/icons/close-bracket.png";
import { motion } from "framer-motion";
import { Cursor, useTypewriter } from "react-simple-typewriter";
import { container } from "./FramerMotionAnimation";

export function Hero() {
  const [homeData, setHomeData] = useState([]);
  useEffect(() => {
    const fetchHomeData = async () => {
      const response = await fetch(
        "https://c63q0m04.api.sanity.io/v2023-02-02/data/query/production?query=*[_type == 'home']"
      );
      if (!response.ok) {
        const message = `An error has occured: ${response.status}`;
        throw new Error(message);
      }
      const data = await response.json();
      setHomeData(data.result[0]);
    };

    fetchHomeData().catch(console.error);
  }, []);

  const [text] = useTypewriter({
    words:
      homeData?.hero_description === undefined
        ? ["Welcome to IT Community's Website."]
        : homeData.hero_description,
    loop: true,
  });

  return (
    <>
      <section className="relative h-screen flex items-center flex-col overflow-hidden">
        <motion.img
          initial={{ rotate: -12 }}
          animate={{ rotate: 0 }}
          whileHover={{ rotate: 360 }}
          transition={{ repeat: Infinity, repeatType: "reverse", duration: 2 }}
          className="absolute sm:w-20 sm:h-20 xs:w-16 xs:h-16 w-12 h-12 sm:top-40 top-24 sm:-left-1 left-2 -rotate-12"
          src={binary_icon}
          alt="binary icon floating image"
        />
        <motion.img
          initial={{ rotate: 12 }}
          animate={{ rotate: 0 }}
          whileHover={{ rotate: 360 }}
          transition={{ repeat: Infinity, repeatType: "reverse", duration: 2 }}
          className="absolute sm:w-20 sm:h-20 xs:w-16 xs:h-16 w-12 h-12 bottom-40 sm:-right-1 right-2 rotate-12"
          src={terminal_icon}
          alt="terminal icon floating image"
        />
        <div className="flex flex-col md:w-3/5 m-auto">
          <div className="flex items-center justify-between">
            <motion.img
              initial={{ opacity: 0, x: 200 }}
              whileInView={{ opacity: 1, x: 0 }}
              whileHover={{ rotate: 12 }}
              transition={{
                duration: 1,
                ease: [0.6, -0.05, 0.01, 0.9],
              }}
              className="sm:w-20 sm:h-20 xs:w-16 xs:h-16 w-12 h-12 sm:block hidden"
              src={open_bracket_icon}
              alt=""
            />
            <motion.h1
              initial="hidden"
              whileInView="visible"
              variants={container}
              className="md:text-lg sm:text-md text-sm sm:h-16 h-24 font-ibm text-white text-center px-8"
            >
              <span>{text}</span>
              <Cursor cursorColor="#EC2028" cursorStyle="_" />
            </motion.h1>
            <motion.img
              initial={{ opacity: 0, x: 200 }}
              whileInView={{ opacity: 1, x: 0 }}
              whileHover={{ rotate: -12 }}
              transition={{
                duration: 1,
                ease: [0.6, -0.05, 0.01, 0.9],
              }}
              className="sm:w-20 sm:h-20 xs:w-16 xs:h-16 w-12 h-12 sm:block hidden"
              src={close_bracket_icon}
              alt=""
            />
          </div>
          <div className="sm:mt-24 mt-16 text-center flex sm:flex-row flex-col self-center">
            {homeData.secondary_gray_button && (
              <motion.a
                initial={{ opacity: 0, x: -100 }}
                animate={{ opacity: 1, x: 0 }}
                whileHover={{ scale: 1.05 }}
                whileTap={{ scale: 0.95 }}
                transition={{
                  duration: 0.3,
                }}
                href="mailto:contact.itc.blida@gmail.com"
                rel="noopener noreferrer"
                className="text-white border text-center uppercase font-ibm sm:px-4 px-2 py-3 sm:text-sm text-xs mx-4 sm:mt-0 mt-4 hover:bg-white hover:text-blackish transition-all duration-300"
              >
                {homeData.secondary_gray_button.secondary_gray_text}
              </motion.a>
            )}
            {homeData.primary_red_button && (
              <motion.a
                initial={{ opacity: 0, x: 100 }}
                animate={{ opacity: 1, x: 0 }}
                whileHover={{ scale: 1.05 }}
                whileTap={{ scale: 0.95 }}
                transition={{
                  duration: 0.3,
                }}
                href={
                  !homeData.primary_red_button.primary_red_button_link
                    ? "#"
                    : homeData.primary_red_button.primary_red_button_link
                }
                target="_blank"
                rel="noopener noreferrer"
                className="text-white border text-center uppercase font-ibm sm:px-4 px-2 py-3 sm:text-sm text-xs sm:mt-0 mt-4 mx-4 bg-dark-red hover:bg-light-red transition-all duration-300"
              >
                {homeData.primary_red_button.primary_red_button_text}
              </motion.a>
            )}
          </div>
        </div>
      </section>
    </>
  );
}

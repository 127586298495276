const transition = { duration: 1.2, ease: [0.6, -0.05, 0.01, 0.9] };

const containerList = {
  hidden: {
    // opacity: 0,
    transition: {
      when: "afterChildren",
    },
  },
  show: {
    opacity: 1,
    transition: {
      when: "beforeChildren",
      staggerChildren: 0.8,
    },
  },
};

const listItemLeft = {
  hidden: { opacity: 0, x: "-100%" },
  show: { opacity: 1, x: 0 },
};

const listItemRight = {
  hidden: { opacity: 0, x: "100%" },
  show: { opacity: 1, x: 0 },
};

const previousEventsItem = {
  hidden: { opacity: 0, x: 400 },
  show: { opacity: 1, x: 0 },
};

const container = {
  visible: {
    transition: {
      staggerChildren: 0.025,
    },
  },
};

export {
  container,
  containerList,
  transition,
  listItemLeft,
  listItemRight,
  previousEventsItem,
};

import { useState } from "react";
import { SidebarContext } from "../context/SidebarContext";
import Cursor from "./Cursor";
import Footer from "./Footer";
import Sidebar from "./Sidebar";
import logo from "../images/newlogo.png";
import Navbar from "./Navbar";
import "../css/style.css";

export default function Layout({ children }) {
  const [sidebar, setSidebar] = useState(false);
  const [hovered, setHovered] = useState(false);

  const showSidebar = () => {
    setSidebar(true);
  };

  return (
    <>
      <SidebarContext.Provider
        value={{ sidebar, setSidebar, hovered, setHovered }}
      >
        <Cursor />
        <Sidebar />
        <Navbar logo={logo} showSidebar={showSidebar} />
        <main className="flex-1 overflow-hidden">{children}</main>
        <Footer />
      </SidebarContext.Provider>
    </>
  );
}

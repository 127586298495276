// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries
import { getFirestore } from "firebase/firestore";

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyATBh3o4MhfUhyXNWKTPwZAEvuQ6IkBPnQ",
  authDomain: "itc-website-01.firebaseapp.com",
  projectId: "itc-website-01",
  storageBucket: "itc-website-01.appspot.com",
  messagingSenderId: "524922104516",
  appId: "1:524922104516:web:2c0827d53d9e69e1743791",
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);

const db = getFirestore(app);

export { db };

import { useState } from "react";
import { collection, addDoc } from "firebase/firestore";
import { db } from "../firebase/config";

export default function Newsletter() {
  const EMAIL_REGEX = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+.[A-Z]{2,4}$/i;
  const [email, setEmail] = useState(null);
  const [validEmail, setValidEmail] = useState(false);
  const [success, setSuccess] = useState("");
  const [error, setError] = useState("");

  const onChangeEmail = (e) => {
    if (EMAIL_REGEX.test(e.target.value)) {
      setValidEmail(true);
      setEmail(e.target.value);
    } else {
      setValidEmail(false);
    }
  };

  const handleRegisterEmail = async (e) => {
    e.preventDefault();
    if (validEmail) {
      setError("");
      /***** REGISTER EMAIL TO FIRESTORE */
      try {
        await addDoc(collection(db, "emails"), {
          email: email,
        });
      } catch (e) {
        setError("Server Error");
      }
      /***** REGISTER EMAIL TO FIRESTORE */
      setSuccess("Thank you for Subscribing!");
    } else {
      setSuccess("");
      setError("Please, Enter Valid Email!");
    }
  };

  return (
    <section className="pb-32 pt-16">
      <div className="flex flex-col items-center justify-center bg-white md:px-36 sm:px-8 px-4 py-8">
        <h2 className="font-ibm md:text-2xl text-xl text-center font-bold py-8 uppercase tracking-wider">
          Subscribe to our Newsletter
        </h2>
        <p className="text-center font-ibm font-bold text-md">Coming Soon...</p>
        <p className="text-center font-ibm font-light text-sm mt-3">
          please register your email to be added to the list of our contacts.
        </p>
        <div className="relative md:w-[500px] w-full flex sm:flex-row flex-col justify-center gap-4 md:py-4 py-2">
          <form className="contents" action="">
            <div className="flex flex-col w-full">
              {error && (
                <div className="my-3 text-center">
                  <small className="font-ibm text-dark-red text-lg">
                    {error}
                  </small>
                </div>
              )}
              {success && (
                <div className="my-3 text-center">
                  <small className="font-ibm text-green-600 text-lg">
                    {success}
                  </small>
                </div>
              )}
              <div className="flex w-full mx-auto">
                <input
                  type="email"
                  className="border border-gray-300 w-full focus:outline-dark-red text-blackish font-ibm visited:border-light-red px-2 py-1 focus:rounded-none"
                  placeholder="yourEmail@****.com"
                  onChange={onChangeEmail}
                />
                <button
                  type="submit"
                  onClick={handleRegisterEmail}
                  className="text-white border text-center uppercase font-ibm sm:px-4 px-2 sm:py-3 py-2 sm:text-sm text-xs sm:mt-0 bg-dark-red hover:bg-light-red hover:text-blackish transition-all duration-300 sm:self-end self-center mx-2"
                >
                  submit
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </section>
  );
}

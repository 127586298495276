import Layout from "../components/Layout";
import not_found from "../images/error.png";
import binary_icon from "../images/icons/binary-code.png";
import terminal_icon from "../images/icons/terminal.png";
import { motion } from "framer-motion";

export default function NoPage() {
  return (
    <Layout>
      <section className="relative text-white font-ibm md:px-16 px-4 overflow-hidden text-center py-16">
        <motion.img
          initial={{ rotate: -12 }}
          animate={{ rotate: 0 }}
          whileHover={{ rotate: 360 }}
          transition={{ repeat: Infinity, repeatType: "reverse", duration: 2 }}
          className="absolute sm:w-20 sm:h-20 xs:w-16 xs:h-16 w-12 h-12 sm:top-40 top-24 sm:-left-1 left-2 -rotate-12 sm:block hidden   "
          src={binary_icon}
          alt="binary icon floating image"
        />
        <motion.img
          initial={{ rotate: 12 }}
          animate={{ rotate: 0 }}
          whileHover={{ rotate: 360 }}
          transition={{ repeat: Infinity, repeatType: "reverse", duration: 2 }}
          className="absolute sm:w-20 sm:h-20 xs:w-16 xs:h-16 w-12 h-12 bottom-40 sm:-right-1 right-2 rotate-12 sm:block hidden"
          src={terminal_icon}
          alt="terminal icon floating image"
        />
        <h1 className="sm:text-3xl text-xl mt-12 mb-12 text-center uppercase font-bold">
          404 Page Not Found.
        </h1>
        <img className="mx-auto" src={not_found} alt="not found error." />
        <h2 className="sm:text-md text-sm mt-16">
          LOOKS LIKE YOU ARE LOST. <br />
          THIS IS NOT THE WEB PAGE YOU ARE LOOKING FOR{" "}
        </h2>
      </section>
    </Layout>
  );
}

import Layout from "../components/Layout";
import speaker_icon from "../images/icons/speaker.png";
import audience_icon from "../images/icons/audience.png";
import { motion } from "framer-motion";
import { Link } from "react-router-dom";
import { useEffect, useState } from "react";
import { urlFor } from "../sanity";
import moment from "moment";
import { useDocumentTitle } from "../hooks/useDocumentTitle";

export default function EventsPage() {
  /**
   * FETCH DATA FROM SANITY API
   */
  const [eventsData, setEventData] = useState([]);
  useDocumentTitle("IT Community Events");

  useEffect(() => {
    const getEventData = async () => {
      const response = await fetch(
        "https://c63q0m04.api.sanity.io/v2023-02-02/data/query/production?query=*[_type == 'event'] | order(date desc)"
      );
      if (!response.ok) {
        const message = `An error has occured: ${response.status}`;
        throw new Error(message);
      }
      const data = await response.json();
      setEventData(data.result);
    };

    getEventData().catch(console.error);
  }, []);

  const transition = { duration: 1.2, ease: [0.6, -0.05, 0.01, 0.9] };

  const containerList = {
    hidden: {
      transition: {
        when: "afterChildren",
      },
    },
    show: {
      opacity: 1,
      transition: {
        when: "beforeChildren",
        staggerChildren: 0.8,
      },
    },
  };

  const previousEventsItem = {
    hidden: { opacity: 0, x: 400 },
    show: { opacity: 1, x: 0 },
  };
  return (
    <>
      <Layout>
        <section className="text-white font-ibm xl:px-20 lg:px-16 px-8 m-auto overflow-hidden">
          <motion.nav
            initial={{ opacity: 0, x: "-500" }}
            animate={{ opacity: 1, x: 0 }}
            transition={transition}
            className="flex mt-16 mb-12 px-5 py-3 text-gray-700 rounded-lg bg-[#232323]"
            aria-label="Breadcrumb"
          >
            <ol className="inline-flex items-center space-x-1 md:space-x-3">
              <li className="inline-flex items-center">
                <Link
                  to={"/"}
                  className="inline-flex items-center text-sm font-medium text-gray-500 hover:text-dark-red"
                >
                  <svg
                    aria-hidden="true"
                    className="w-4 h-4 mr-2"
                    fill="currentColor"
                    viewBox="0 0 20 20"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path d="M10.707 2.293a1 1 0 00-1.414 0l-7 7a1 1 0 001.414 1.414L4 10.414V17a1 1 0 001 1h2a1 1 0 001-1v-2a1 1 0 011-1h2a1 1 0 011 1v2a1 1 0 001 1h2a1 1 0 001-1v-6.586l.293.293a1 1 0 001.414-1.414l-7-7z"></path>
                  </svg>
                  Home
                </Link>
              </li>
              <li>
                <div className="flex items-center">
                  <svg
                    aria-hidden="true"
                    className="w-6 h-6 text-gray-500"
                    fill="currentColor"
                    viewBox="0 0 20 20"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      fillRule="evenodd"
                      d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z"
                      clipRule="evenodd"
                    ></path>
                  </svg>
                  <Link
                    to={"/events"}
                    className="ml-1 text-sm font-medium text-white hover:text-dark-red md:ml-2"
                  >
                    Events
                  </Link>
                </div>
              </li>
            </ol>
          </motion.nav>

          <motion.div
            variants={containerList}
            initial="hidden"
            animate="show"
            className="grid xl:grid-cols-3 md:grid-cols-2 grid-cols-1 gap-8 mb-12"
          >
            {eventsData.map((item, index) => {
              return (
                <Link
                  to={"/events/" + item.slug.current}
                  key={index}
                  className="hover:scale-105 transition ease-out duration-500"
                >
                  <motion.div
                    variants={previousEventsItem}
                    whileTap={{ scale: 0.95 }}
                    dragConstraints={{ left: 0, right: 0, top: 0, bottom: 0 }}
                    className="relative bg-[#232323] rounded-2xl p-8 z-10"
                    key={index}
                  >
                    <div className="absolute md:w-[250px] md:h-[250px] w-[200px] h-[200px] bottom-0 right-0 overflow-hidden sm:block hidden">
                      {item.main_image && (
                        <img
                          className="absolute -bottom-10 sm:-right-20 right-0"
                          src={urlFor(item.main_image).url()}
                          alt=""
                        />
                      )}
                    </div>
                    <div className="flex justify-between items-center mb-4">
                      {item.icon && (
                        <img
                          className="w-12 h-12"
                          src={urlFor(item.icon).url()}
                          alt=""
                        />
                      )}
                      <small className="text-[#ccc]">
                        {moment(item.date).format("Do MMM, YYYY")}
                      </small>
                    </div>
                    {item.tags &&
                      item.tags.map((tag, index) => {
                        return (
                          <small key={index} className="text-[#ccc]">
                            {(index ? " | " : "") + tag}
                          </small>
                        );
                      })}
                    <h3 className="mt-4 sm:mb-8 mb-4 font-bold sm:text-2xl text-xl truncate">
                      {item.title}
                    </h3>
                    <div className="flex sm:flex-col flex-row gap-6 my-6">
                      <div>
                        <img className="w-8 h-8" src={audience_icon} alt="" />
                        <p className="text-sm font-bold uppercase mt-2 text-[#ccc]">
                          {item.attendees === 0 ? "--" : item.attendees}{" "}
                          attendees
                        </p>
                      </div>
                      <div>
                        <img className="w-8 h-8" src={speaker_icon} alt="" />

                        <p className="text-sm font-bold uppercase mt-2 text-[#ccc]">
                          {item.speakers === 0 ? "--" : item.speakers} speakers
                        </p>
                      </div>
                    </div>
                    <small className="text-[#ccc]">{item.location}</small>
                  </motion.div>
                </Link>
              );
            })}
          </motion.div>
        </section>
      </Layout>
    </>
  );
}

import React, { useContext, useEffect } from "react";

import { AiOutlineClose } from "react-icons/ai";
import { SidebarContext } from "../context/SidebarContext";

export default function Sidebar() {
  const nav_items = [
    { label: "home", path: "/" },
    { label: "events", path: "/events" },
    { label: "our blog (soon..)", path: "/" },
    { label: "our members (soon..)", path: "/" },
    {
      label: "itcast podcast",
      path: "https://open.spotify.com/show/1nnC8eq7tZFUNUoAbvKrH1?si=3f7dd3069e9c4c2b",
    },
    { label: "our projects (soon..)", path: "/" },
  ];

  const { sidebar, setSidebar, setHovered } = useContext(SidebarContext);

  const closeSidebar = () => {
    setSidebar(false);
  };

  useEffect(() => {
    if (sidebar) {
      /**
       * This will smooth out the animation when hide/show the scroll bar of the body.
       */
      setTimeout(() => {
        document.body.style = "overflow: hidden";
      }, 300);
    } else {
      setTimeout(() => {
        document.body.style = "overflow: auto";
      }, 100);
    }
  }, [sidebar]);

  return (
    <section
      className={`bg-white h-screen fixed top-0 left-0 bottom-0 right-0 overflow-hidden z-40 transition ease-in-out duration-700 translate-x-full ${
        sidebar ? "translate-x-0" : ""
      } `}
    >
      <nav className="w-full p-4 xl:px-32 xl:py-10 md:px-16 flex justify-end items-center">
        <div className="cursor-pointer" onClick={closeSidebar}>
          <AiOutlineClose
            size={"2.5rem"}
            onMouseEnter={() => setHovered(true)}
            onMouseLeave={() => setHovered(false)}
          />
        </div>
      </nav>
      <div className="flex items-center md:justify-start flex-col h-full md:pt-6 pt-4">
        <ul className="font-ibm uppercase text-center">
          {nav_items.map((item, index) => {
            return (
              <li
                key={index}
                className="sm:text-3xl text-2xl mb-12 hover:text-dark-red"
              >
                <a href={item.path}>{item.label}</a>
              </li>
            );
          })}
        </ul>
      </div>
    </section>
  );
}

import React, { useEffect, useState } from "react";
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
} from "@mui/material";
import { motion } from "framer-motion";

import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

export default function FAQ() {
  const [homeData, setHomeData] = useState([]);
  useEffect(() => {
    const fetchData = async () => {
      const response = await fetch(
        "https://c63q0m04.api.sanity.io/v2023-02-02/data/query/production?query=*[_type == 'home']"
      );
      if (!response.ok) {
        const message = `An error has occured: ${response.status}`;
        throw new Error(message);
      }
      const data = await response.json();
      setHomeData(data.result[0]["questions"]);
    };

    fetchData().catch(console.error);
  }, []);

  const frequent_questions = homeData;

  const containerList = {
    hidden: {
      transition: {
        when: "afterChildren",
      },
    },
    show: {
      opacity: 1,
      transition: {
        when: "beforeChildren",
        staggerChildren: 0.8,
      },
    },
  };

  const previousEventsItem = {
    hidden: { opacity: 0, x: 400 },
    show: { opacity: 1, x: 0 },
  };

  const transition = { duration: 1.2, ease: [0.6, -0.05, 0.01, 0.9] };

  return (
    <section className="md:px-16 px-4 py-8 overflow-hidden">
      <h2 className="text-3xl text-white font-ibm my-8 text-center uppercase">
        FAQ's
      </h2>
      <motion.div
        variants={containerList}
        initial="hidden"
        whileInView="show"
        className="md:w-2/3 w-full mx-auto"
      >
        {frequent_questions.map((item, index) => {
          return (
            <motion.div
              key={index}
              variants={previousEventsItem}
              transition={transition}
            >
              <Accordion>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon style={{ color: "#1A1A1A" }} />}
                  aria-controls="panel1a-content"
                  id="panel1a-header"
                  style={{
                    backgroundColor: "white",
                    borderBottom: "solid 2px black",
                  }}
                >
                  <Typography style={{ fontFamily: "IBMPlexMono" }}>
                    {item.question}
                  </Typography>
                </AccordionSummary>
                <AccordionDetails style={{ padding: "2rem 1rem" }}>
                  <Typography style={{ fontFamily: "IBMPlexMono" }}>
                    {item.answer}
                  </Typography>
                </AccordionDetails>
              </Accordion>
            </motion.div>
          );
        })}
      </motion.div>
    </section>
  );
}

import React, { useRef, useState } from "react";
import image1 from "../images/IMG_0029.JPG";
import image2 from "../images/IMG_6715.jpeg";
import image3 from "../images/IMG_4995.JPG";
import atomic_icon from "../images/icons/atomic.png";
import coding_icon from "../images/icons/coding.png";
import code_icon from "../images/icons/code.png";
import { AnimatePresence, motion } from "framer-motion";
import "react-lazy-load-image-component/src/effects/blur.css";
import AnimatedText from "./AnimatedText";
import { Cloudinary } from "@cloudinary/url-gen";

// Import the responsive plugin
import { AdvancedImage, responsive } from "@cloudinary/react";

export default function AboutUs() {
  const aboutus_items = [
    {
      id: 1,
      headingText: "We create IMPACTFUL events.",
      description:
        "We are very proud of our events, Because nobody do them like we do. We are simply the best at them. One of our many Legacies that we organize every year is ITC Talks. A Series of Amazing Conferences by Incredible Guests in different fields. This was our Third Edition ITC TALKS 3.0.",
      pathImage: image1,
      publicId: "itc-about-us-images/IMG_0029_cflqo4.jpg",
    },
    {
      id: 2,
      headingText: "We take AWESOME pictures.",
      description:
        "We are IT Community, and we can't be a community without having beautiful pictures with all of its members. This is our Famour Place in our University, Where it's a tradition to Take a Group Picture after Every Successful Event.",
      pathImage: image2,
      publicId: "itc-about-us-images/IMG_6715_upeuht.jpg",
    },
    {
      id: 3,
      headingText: "We BUILD amazing softwares.",
      description:
        "We are a Tech Community that teaches it's members everything related to Web Development, Mobile Development, Desktop Development, Artificial Intelligence, Design and many more of our amazing teams.",
      pathImage: image3,
      publicId: "itc-about-us-images/IMG_4995_s9nwm1.jpg",
    },
    {
      id: 4,
      headingText: "We SHARE EVERYTHING.",
      description:
        "As individuals, everything that we learn, we organize a session and share our humbled new knowledge with each, we share Eeverything. YES! We even Share Food, Because we our a FAMILY. Our Famous Shout is: IT.....C, IT .....C.",
      pathImage: image1,
      publicId: "itc-about-us-images/IMG_7908_kavd8v.jpg",
    },
  ];

  const [isHovering, setIsHovering] = useState(-1);

  const handleMouseOver = (key) => {
    setIsHovering(key);
  };

  const handleMouseOut = () => {
    setIsHovering(-1);
  };

  const transition = { duration: 1.2, ease: [0.6, -0.05, 0.01, 0.9] };

  const containerList = {
    hidden: {
      opacity: 0,
      transition: {
        when: "afterChildren",
      },
    },
    show: {
      opacity: 1,
      transition: {
        when: "beforeChildren",
        staggerChildren: 0.8,
      },
    },
  };

  const listItem = {
    hidden: { opacity: 0, x: 500 },
    show: { opacity: 1, x: 0 },
  };

  const ref = useRef(null);

  const placeholderText = [
    {
      type: "paragraph",
      text: "WE LEARN, CREATE and BUILD",
      color_hidden: "#1A1A1A",
      color_visible: "#EC2028",
    },
    {
      type: "paragraph",
      text: "BUT MOST IMPORTANTLY WE SHARE.",
    },
  ];

  const containerAnimationText = {
    visible: {
      transition: {
        staggerChildren: 0.025,
      },
    },
  };

  // Create and configure your Cloudinary instance.
  const cld = new Cloudinary({
    cloud: {
      cloudName: "dmbgefoy9",
    },
  });

  // const myImage = cld.image("itc-about-us-images/IMG_0029_cflqo4.jpg");
  // let myImage;

  return (
    <>
      <section ref={ref} className="my-8">
        <motion.div
          variants={containerList}
          initial="hidden"
          whileInView="show"
          className="relative grid md:grid-cols-2 grid-cols-1 items-center gap-4 md:px-20 sm:px-5 px-2 py-24 overflow-hidden"
        >
          <motion.img
            initial={{ rotate: -12 }}
            animate={{ rotate: 0 }}
            whileHover={{ rotate: 360 }}
            transition={{
              repeat: Infinity,
              repeatType: "reverse",
              duration: 2,
            }}
            className="absolute sm:w-20 sm:h-20 xs:w-16 xs:h-16 w-12 h-12 top-2 right-1/3 -rotate-12"
            src={code_icon}
            alt=""
          />
          <motion.img
            initial={{ rotate: 12 }}
            animate={{ rotate: 0 }}
            whileHover={{ rotate: 360 }}
            transition={{
              repeat: Infinity,
              repeatType: "reverse",
              duration: 2,
            }}
            className="absolute sm:w-20 sm:h-20 xs:w-16 xs:h-16 w-12 h-12 bottom-0 -left-2 rotate-12"
            src={atomic_icon}
            alt=""
          />
          <motion.img
            initial={{ rotate: 12 }}
            animate={{ rotate: 0 }}
            whileHover={{ rotate: 360 }}
            transition={{
              repeat: Infinity,
              repeatType: "reverse",
              duration: 2,
            }}
            className="absolute sm:w-20 sm:h-20 xs:w-16 xs:h-16 w-12 h-12 bottom-4 -right-2 rotate-12"
            src={coding_icon}
            alt=""
          />
          <AnimatePresence>
            {aboutus_items.map((item, index) => {
              return (
                <motion.div
                  className="relative overflow-hidden"
                  variants={listItem}
                  transition={transition}
                  key={index}
                >
                  <div
                    className="hover:bg-blackish/[0.65] absolute top-0 left-0 right-0 bottom-0 transition ease-in-out duration-300 cursor-pointer z-20"
                    onMouseOver={() => handleMouseOver(item.id)}
                    onMouseLeave={() => handleMouseOut()}
                  ></div>
                  <aside
                    className={`absolute bottom-10 sm:left-4 left-0 px-2 text-white font-ibm transition ease-in-out duration-500 z-20
                    translate-y-0 visible pointer-events-none
                    ${
                      isHovering === item.id
                        ? "translate-y-0 visible"
                        : "sm:translate-y-32 translate-y-40 invisible"
                    }
                    `}
                  >
                    <h3 className="my-4 text-xl font-bold">
                      {item.headingText}
                    </h3>
                    <p className="text-sm">{item.description}</p>
                  </aside>
                  <div
                    key={item.headingText}
                    className={`w-full h-full transition ease-in-out duration-300 z-10 mx-auto ${
                      isHovering === item.id
                        ? "scale-105 grayscale"
                        : "grayscale-0"
                    }`}
                  >
                    <AdvancedImage
                      cldImg={cld.image(item.publicId)}
                      plugins={[responsive({ steps: 200 })]}
                    />
                  </div>
                </motion.div>
              );
            })}
          </AnimatePresence>
        </motion.div>
        <div className="h-96 flex items-center justify-center bg-white mt-24">
          <motion.h1
            initial="hidden"
            whileInView="visible"
            variants={containerAnimationText}
            className="text-center font-ibm sm:text-xl text-md px-4"
          >
            {placeholderText.map((item, index) => {
              return <AnimatedText {...item} key={index} />;
            })}
          </motion.h1>
        </div>
      </section>
    </>
  );
}

import { useContext } from "react";
import { BiMenu } from "react-icons/bi";
import { SidebarContext } from "../context/SidebarContext";

export default function Navbar({ logo, showSidebar }) {
  const { setHovered } = useContext(SidebarContext);
  return (
    <nav className="w-full xl:px-32 md:px-16 px-2 flex justify-between items-center">
      <a href="/">
        <img
          src={logo}
          className="w-24 p-4"
          alt="IT Community White Red Logo"
        />
      </a>
      <div className="cursor-pointer" onClick={showSidebar}>
        <BiMenu
          color="white"
          size={"2.5rem"}
          onMouseEnter={() => setHovered(true)}
          onMouseLeave={() => setHovered(false)}
        />
      </div>
    </nav>
  );
}

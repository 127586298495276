import { motion } from "framer-motion";
import { useEffect, useState } from "react";
import Layout from "../components/Layout";
import { FaFacebook, FaInstagram, FaLinkedin } from "react-icons/fa";
import moment from "moment";

import {
  VerticalTimeline,
  VerticalTimelineElement,
} from "react-vertical-timeline-component";
import "react-vertical-timeline-component/style.min.css";
import { Link, useLocation } from "react-router-dom";
import { urlFor } from "../sanity";
import { transition } from "../components/FramerMotionAnimation";
import { useDocumentTitle } from "../hooks/useDocumentTitle";

export default function EventDetails() {
  useDocumentTitle("IT Community Events");

  const animationConfiguration = {
    initial: { x: -800, opacity: 0 },
    animate: { x: 0, opacity: 1 },
    exit: { opacity: 0 },
  };
  /**
   * FETCH DATA FROM SANITY API
   */
  const [eventData, setEventData] = useState([]);
  const [speakersData, setSpeakersData] = useState([]);
  const [galleryData, setGalleryData] = useState([]);
  const [sessionsData, setSessionsData] = useState([]);

  const location = useLocation();
  const queryUrl =
    "https://c63q0m04.api.sanity.io/v2023-02-02/data/query/production";
  useEffect(() => {
    const fetchData = async (location) => {
      const event_slug = location.pathname.split("events/")[1];
      const query = `*[ _type == "event" && slug.current == "${event_slug}"]{
        'event' : *[_type == 'event' && slug.current == "${event_slug}"],
        'speakers': *[_type == 'speaker' && references(^._id)],
        'gallery': *[_type == 'gallery' && references(^._id)],
        'sessions': *[_type == 'session' && references(^._id)] | order(time asc){
          ...,
          speaker-> 
        }   
      }`;
      const response = await fetch(
        `${queryUrl}?query=${encodeURIComponent(query)}`
      );
      if (!response.ok) {
        const message = `An error has occured: ${response.status}`;
        throw new Error(message);
      }
      const res = await response.json();
      let speakers_data = res.result[0]["speakers"];
      let event_data = res.result[0]["event"][0];
      let gallery_data = res.result[0]["gallery"];
      let session_data = res.result[0]["sessions"];
      setSpeakersData(speakers_data);
      setEventData(event_data);
      setGalleryData(gallery_data);
      setSessionsData(session_data);
    };

    fetchData(location).catch(console.error);
  }, [location]);

  return (
    <Layout>
      <motion.div
        variants={animationConfiguration}
        initial="initial"
        animate="animate"
        exit="exit"
        transition={{ duration: 1.2 }}
      >
        <section className="text-white font-ibm xl:px-20 md:px-16 px-6 my-16">
          <motion.nav
            initial={{ opacity: 0, x: "-500" }}
            animate={{ opacity: 1, x: 0 }}
            transition={transition}
            className="flex mt-16 mb-12 px-5 py-3 text-gray-700 rounded-lg bg-[#232323]"
            aria-label="Breadcrumb"
          >
            <ol className="inline-flex items-center space-x-1 md:space-x-3">
              <li className="inline-flex items-center">
                <Link
                  to={"/"}
                  className="inline-flex items-center text-sm font-medium text-gray-500 hover:text-dark-red"
                >
                  <svg
                    aria-hidden="true"
                    className="w-4 h-4 mr-2"
                    fill="currentColor"
                    viewBox="0 0 20 20"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path d="M10.707 2.293a1 1 0 00-1.414 0l-7 7a1 1 0 001.414 1.414L4 10.414V17a1 1 0 001 1h2a1 1 0 001-1v-2a1 1 0 011-1h2a1 1 0 011 1v2a1 1 0 001 1h2a1 1 0 001-1v-6.586l.293.293a1 1 0 001.414-1.414l-7-7z"></path>
                  </svg>
                  Home
                </Link>
              </li>
              <li className="inline-flex items-center">
                <svg
                  aria-hidden="true"
                  className="w-6 h-6 text-gray-500"
                  fill="currentColor"
                  viewBox="0 0 20 20"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fillRule="evenodd"
                    d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z"
                    clipRule="evenodd"
                  ></path>
                </svg>
                <Link
                  to={"/events"}
                  className="inline-flex items-center text-sm font-medium text-gray-500 hover:text-dark-red"
                >
                  Events
                </Link>
              </li>
              <li>
                <div className="flex items-center">
                  <svg
                    aria-hidden="true"
                    className="w-6 h-6 text-gray-500"
                    fill="currentColor"
                    viewBox="0 0 20 20"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      fillRule="evenodd"
                      d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z"
                      clipRule="evenodd"
                    ></path>
                  </svg>
                  {eventData.slug && (
                    <Link
                      to={"/events/" + eventData.slug.current}
                      className="ml-1 text-sm font-medium text-white hover:text-dark-red md:ml-2"
                    >
                      {eventData.title}
                    </Link>
                  )}
                </div>
              </li>
            </ol>
          </motion.nav>
        </section>
        {galleryData[0] && (
          <div className="w-full h-96 mt-8 flex bg-[#232323]">
            <ul className="w-full h-full list-none flex gap-4 overflow-x-scroll overflow-y-hidden whitespace-nowrap mx-auto">
              {galleryData[0]["images"].map((item, index) => {
                return (
                  <li
                    key={index}
                    className="flex-[0_0_500px] h-full flex items-center"
                  >
                    <img
                      className="w-[500px] h-[320px] object-cover"
                      src={urlFor(item).url()}
                      alt="gallery data"
                    />
                  </li>
                );
              })}
            </ul>
          </div>
        )}
        <section className="text-white font-ibm xl:px-20 md:px-16 px-6 my-16">
          <h2 className="text-xl uppercase font-bold">what is it?</h2>
          <p className="py-12">{eventData.description}</p>
        </section>
        {sessionsData.length > 0 ? (
          <VerticalTimeline className="font-ibm text-white capitalize">
            {sessionsData.map((item, index) => {
              if (!item.isbreak) {
                return (
                  <VerticalTimelineElement
                    key={index}
                    className="vertical-timeline-element--work"
                    contentStyle={{ background: "#232323", color: "#fff" }}
                    contentArrowStyle={{ borderRight: "7px solid  #fff" }}
                    date={moment(item.time).format("HH:mm A")}
                    iconStyle={{ background: "#232323", color: "#fff" }}
                  >
                    <h3 className="vertical-timeline-element-title">
                      {item.title}
                    </h3>
                    <h4 className="vertical-timeline-element-subtitle pt-4">
                      {item.speaker.name}
                    </h4>
                    <p> {item.speaker.bio}</p>
                  </VerticalTimelineElement>
                );
              } else {
                return (
                  <VerticalTimelineElement
                    key={index}
                    iconStyle={{ background: "#BD2028" }}
                    date={moment(item.time).format("HH:mm A")}
                    contentStyle={{ background: "#fff", color: "#fff" }}
                    contentArrowStyle={{ borderRight: "7px solid  #fff" }}
                  >
                    <h3 className="vertical-timeline-element-title text-[#232323]">
                      {item.title}
                    </h3>
                  </VerticalTimelineElement>
                );
              }
            })}
          </VerticalTimeline>
        ) : (
          <p className="font-ibm text-white capitalize text-center">
            NO AGENDA YET
          </p>
        )}

        <section className="text-white font-ibm xl:px-32 md:px-16 px-6 my-16">
          <h2 className="text-xl uppercase font-bold">speakers</h2>
          {speakersData.length > 0 ? (
            <div className="grid md:grid-cols-3 grid-cols-1 justify-items-center my-8">
              {speakersData.map((item, index) => {
                return (
                  <div key={index} className="flex flex-col items-center my-4">
                    <img
                      className="rounded-full w-80 h-80 object-cover"
                      src={urlFor(item.picture).url()}
                      alt="speaker"
                    />
                    <aside className="text-center">
                      <p className="font-bold uppercase my-4 text-lg">
                        {item.name}
                      </p>
                      <span className="font-light uppercase my-2 text-md">
                        {item.company}
                      </span>
                      <ul className="flex my-4 justify-center">
                        {item.facebookUrl && (
                          <li className="text-3xl mx-2">
                            <a
                              target="_blank"
                              rel="noreferrer"
                              href={item.facebookUrl}
                            >
                              <FaFacebook />
                            </a>
                          </li>
                        )}
                        {item.instagramUrl && (
                          <li className="text-3xl mx-2">
                            <a
                              target="_blank"
                              rel="noreferrer"
                              href={item.instagramUrl}
                            >
                              <FaInstagram />
                            </a>
                          </li>
                        )}
                        {item.linkedinUrl && (
                          <li className="text-3xl mx-2">
                            <a
                              target="_blank"
                              rel="noreferrer"
                              href={item.linkedinUrl}
                            >
                              <FaLinkedin />
                            </a>
                          </li>
                        )}
                      </ul>
                    </aside>
                  </div>
                );
              })}
            </div>
          ) : (
            <p className="font-ibm text-white capitalize text-center my-16">
              NO SPEAKERS YET
            </p>
          )}
        </section>
      </motion.div>
    </Layout>
  );
}

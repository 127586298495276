import red_folder from "../images/icons/red_folder.png";
import speaker_icon from "../images/icons/speaker.png";
import audience_icon from "../images/icons/audience.png";
import { motion } from "framer-motion";

import { Link } from "react-router-dom";
import { useEffect } from "react";
import { useState } from "react";
import { urlFor } from "../sanity";
import moment from "moment";
import {
  containerList,
  transition,
  // listItemLeft,
  listItemRight,
  previousEventsItem,
} from "./FramerMotionAnimation";

export default function Events() {
  /**
   * FETCH DATA FROM SANITY API
   */
  const [eventsData, setEventData] = useState([]);
  const [homeData, setHomeData] = useState([]);
  useEffect(() => {
    const getEventData = async () => {
      const response = await fetch(
        "https://c63q0m04.api.sanity.io/v2023-02-02/data/query/production?query=*[_type == 'event'][0..8] | order(date desc)"
      );
      if (!response.ok) {
        const message = `An error has occured: ${response.status}`;
        throw new Error(message);
      }
      const data = await response.json();
      /**
       * One caveat is that if you want to use the result from the asynchronous code,
       * You should do so inside the fetchData function, not outside.
       */
      setEventData(data.result);
    };

    const fetchHomeData = async () => {
      const response = await fetch(
        "https://c63q0m04.api.sanity.io/v2023-02-02/data/query/production?query=*[_type == 'home']"
      );
      if (!response.ok) {
        const message = `An error has occured: ${response.status}`;
        throw new Error(message);
      }
      const data = await response.json();
      setHomeData(data.result[0]);
    };

    fetchHomeData().catch(console.error);
    getEventData().catch(console.error);
  }, []);

  return (
    <section className="text-white font-ibm md:px-16 px-4 overflow-hidden">
      <motion.h2
        initial={{ opacity: 0, x: -500 }}
        whileInView={{ opacity: 1, x: 0 }}
        transition={transition}
        className="text-3xl mt-24 mb-12 sm:text-left text-center uppercase"
      >
        {homeData.current_event_title}
      </motion.h2>
      <motion.div
        variants={containerList}
        initial="hidden"
        whileInView="show"
        className="grid sm:grid-cols-2 grid-cols-1 lg:gap-8 gap-16"
      >
        <motion.div
          variants={previousEventsItem}
          transition={transition}
          className="xl:h-[500px] xs:h-[350px] h-[320px] mx-auto relative"
        >
          <img className="h-full object-cover" src={red_folder} alt="" />
          <motion.div className="w-full absolute sm:bottom-10 bottom-4 sm:left-8">
            {homeData.image && (
              <img
                className="w-full rounded-xl xl:h-[320px] xs:h-[280px] h-[200px] object-cover mx-auto"
                src={urlFor(homeData.image).url()}
                alt=""
              />
            )}
          </motion.div>
        </motion.div>
        <motion.div
          variants={listItemRight}
          transition={transition}
          className="flex flex-col items-center justify-center"
        >
          <p className="sm:my-8 my-4 sm:text-md text-sm text-center">
            {homeData.current_event_description}
          </p>
          <a
            href={homeData.join_us_link}
            className="text-white border text-center uppercase font-ibm sm:px-4 px-2 sm:py-3 py-2 sm:text-sm text-xs sm:mt-0 mt-2 bg-dark-red hover:bg-light-red hover:text-blackish transition-all duration-300"
            target="_blank noreferrer"
          >
            join us
          </a>
        </motion.div>
      </motion.div>

      <motion.h2
        initial={{ opacity: 0, x: -500 }}
        whileInView={{ opacity: 1, x: 0 }}
        transition={transition}
        className="text-3xl mt-24 mb-12 uppercase sm:text-left text-center"
      >
        Previous Events
      </motion.h2>
      <motion.div
        variants={containerList}
        initial="hidden"
        whileInView="show"
        className="grid xl:grid-cols-3 md:grid-cols-2 grid-cols-1 gap-8 mb-12 sm:px-0 px-6"
      >
        {eventsData.map((item, index) => {
          return (
            <Link to={"events/" + item.slug.current} key={index}>
              <motion.div
                drag
                variants={previousEventsItem}
                whileTap={{ scale: 0.95 }}
                dragConstraints={{ left: 0, right: 0, top: 0, bottom: 0 }}
                className="relative bg-[#232323] rounded-2xl p-8 hover:scale-105 transition ease-out duration-500 z-10"
                key={index}
              >
                <div className="absolute md:w-[250px] md:h-[250px] w-[200px] h-[200px] bottom-0 right-0 overflow-hidden sm:block hidden">
                  {item.main_image && (
                    <img
                      className="absolute -bottom-10 sm:-right-20 right-0"
                      src={urlFor(item.main_image).url()}
                      alt=""
                    />
                  )}
                </div>
                <div className="flex justify-between items-center mb-4">
                  {item.icon && (
                    <img
                      className="w-12 h-12"
                      src={urlFor(item.icon).url()}
                      alt=""
                    />
                  )}
                  <small className="text-[#ccc]">
                    {moment(item.date).format("Do MMM, YYYY")}
                  </small>
                </div>
                {item.tags &&
                  item.tags.map((tag, index) => {
                    return (
                      <small key={index} className="text-[#ccc]">
                        {(index ? " | " : "") + tag}
                      </small>
                    );
                  })}
                <h3 className="mt-4 sm:mb-8 mb-4 font-bold sm:text-2xl text-xl truncate">
                  {item.title}
                </h3>
                <div className="flex sm:flex-col flex-row gap-6 my-6">
                  <div>
                    <img className="w-8 h-8" src={audience_icon} alt="" />
                    <p className="text-sm font-bold uppercase mt-2 text-[#ccc]">
                      {item.attendees == 0 ? "--" : item.attendees} attendees
                    </p>
                  </div>
                  <div>
                    <img className="w-8 h-8" src={speaker_icon} alt="" />

                    <p className="text-sm font-bold uppercase mt-2 text-[#ccc]">
                      {item.speakers == 0 ? "--" : item.speakers} speakers
                    </p>
                  </div>
                </div>
                <small className="text-[#ccc]">{item.location}</small>
              </motion.div>
            </Link>
          );
        })}
      </motion.div>
    </section>
  );
}

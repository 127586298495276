// import { useState } from "react";
import AboutUs from "./components/AboutUs";
import { Hero } from "./components/Hero";
import Events from "./components/Events";
import FAQ from "./components/FAQ";
import Newsletter from "./components/Newsletter";
// import logo_loading from "./images/icons/loader_page.gif";
import Layout from "./components/Layout";
import { useDocumentTitle } from "./hooks/useDocumentTitle";

function App() {
  useDocumentTitle("IT Community");
  // useEffect(() => {
  //   setLoading(true);
  //   setTimeout(() => {
  //     setLoading(false);
  //   }, 2000);
  // }, []);

  // const [loading, setLoading] = useState(false);

  return (
    <>
      {/* {loading ? (
        <div className="bg-white h-screen fixed top-0 left-0 bottom-0 right-0 overflow-hidden z-40">
          <div className="h-full flex justify-center items-center">
            <img className="w-64 h-64 object-cover" src={logo_loading} alt="" />
          </div>
        </div>
      ) : ( */}
      <>
        <Layout>
          <Hero />
          <AboutUs />
          <Events />
          <FAQ />
          <Newsletter />
        </Layout>
      </>
      {/* )} */}
    </>
  );
}

export default App;
